<template>
  <div>
    <TheDesktopNavbarTop
      :site-name="t('layouts.default.site')"
      class="fixed top-0 left-0 z-40 transition-transform shadow"
      :class="{
        '-translate-y-full sm:translate-y-0': scrollDirection === 'downward',
      }"
    />

    <TheMobileNavbarBottom
      @open-navbar="navbarOpen = true"
      class="fixed bottom-0 left-0 z-40 transition-transform"
      :class="{
        'translate-y-full': isReader,
        'translate-y-full sm:translate-y-0': scrollDirection === 'downward',
      }"
      :nav="[
        {
          id: 'home',
          name: t('layouts.default.home'),
          href: href.home,
          icon: IconHome2,
        },
        {
          id: 'discover',
          name: t('layouts.default.discover'),
          href: href.discover.index,
          icon: IconCompass,
        },
        {
          id: 'feed',
          name: t('layouts.default.feed'),
          href: href.feed.index,
          icon: IconMessage,
        },
        {
          id: 'library',
          name: t('layouts.default.library'),
          href: href.library.index,
          icon: IconBookmark,
        },
        {
          id: 'menu',
          name: t('layouts.default.menu'),
          href: '#',
          icon: IconMenu2,
        },
      ]"
    />

    <!-- Site content -->
    <div
      class="bg-neutral-100 text-neutral-800 dark:bg-black dark:text-neutral-200"
      id="mainContainer"
    >
      <main class="mt-16 min-h-[100dvh]">
        <slot />
      </main>
    </div>

    <TheMobileExpandableSidebarLeft
      v-if="!isReader"
      v-model="navbarOpen"
      @change-nav="changeNav"
      :nav="nav"
    />
  </div>
</template>

<script setup lang="ts">
import {
  IconCompass,
  IconBookmark,
  IconMenu2,
  IconQuestionMark,
  IconSettings,
  IconHome2,
  IconBook2,
  IconBooks,
  IconTrophy,
  IconPalette,
  IconMessage,
} from "@tabler/icons-vue";

const nuxtApp = useNuxtApp();
const route = useRoute();
const href = useHref();
const scrollDirection = useScrollDirection();

const { t } = nuxtApp.$i18n.global;

const isReader = computed(
  () =>
    route.params.locale && route.path.includes(`/chapter/${route.params.id}`),
);

const navbarOpen = ref(false);
const nav = computed(() => ({
  site: t("layouts.default.site"),
  current: route.path,
  items: [
    {
      name: t("layouts.default.library"),
      href: href.value.library.index,
      icon: IconBooks,
      current: false,
    },
    {
      name: t("layouts.default.discover"),
      href: href.value.discover.index,
      icon: IconBook2,
      current: true,
    },
    {
      name: t("layouts.default.feed"),
      href: href.value.feed.index,
      icon: IconMessage,
      current: false,
    },
    {
      name: t("layouts.default.publish"),
      href: href.value.publish.overview.index,
      icon: IconPalette,
      current: true,
    },
    {
      name: t("layouts.default.contest"),
      href: href.value.contest.overview.index,
      icon: IconTrophy,
      current: true,
    },
    {
      name: t("layouts.default.settings"),
      href: href.value.settings.profile,
      icon: IconSettings,
      current: false,
    },
    {
      name: t("layouts.default.help"),
      href: "https://help.namicomi.com",
      icon: IconQuestionMark,
      current: false,
    },
  ],
}));

function changeNav(selectedNav: string) {
  nav.value.current = selectedNav;
  navbarOpen.value = false;
}
</script>
